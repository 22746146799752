import React from 'react'
import { InstagramWithCircle as Instagram } from "@styled-icons/entypo-social/InstagramWithCircle"
import { Link } from "react-router-dom";
import useEffect from 'react'

const Contact = () => {


  return (
    <div className='w-screen'>
        <div className='w-[1350px] mx-auto mb-24'>
            <div className='md:ml-8 xl:ml-0 grid xl:grid-cols-7 gap-4'>
            
                <div className='w-[40%] ml-3 md:w-5/6  lg:w-[700px] xl:w-full  mx-1 h-[200px] md:h-[540px] col-span-3 bg-headshot2 bg-cover bg-[center] rounded-xl'></div>
                
                <div className='w-full lg:h-[540px] col-span-4'>
                    <div className='w-full h-full flex flex-col'>
                        <div className='w-full xl:mt-[15%] lg:ml-6 ml-2'>
                            <h1 className='text-[#E6A500] text-2xl lg:text-6xl font-custom'>SHAWN N. VILLANUEVA</h1>
                            <h1 className='text-gray-100 text-xl pt-2'>(+1) 407 572 3644</h1>
                            <h1 className='text-gray-100 italic text-xl md:text-3xl md:pt-2'>snatesvillamusic@gmail.com <a href="https://www.instagram.com/snatesvillamusic/?hl=en"><Instagram className='text-[#E6A500] hidden md:flex w-12'/></a></h1>                            
                        </div>
                        <div className="pt-12 ml-2 lg:ml-6 ml-4">
                        <h1 className="text-white font-bold text-3xl">Want to get in touch?</h1>
                        <div className="w-full mx-auto">
                            <Link to="/contact">
                            <button className="text-white bg-[#E6A500] mt-4 w-1/6 md:w-2/6 rounded h-8 hover:bg-gray-500 hover:text-[#E6A500]">contact me</button>
                            </Link>
                        </div>
                    </div>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact