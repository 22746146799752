import React from 'react'

const Bio = () => {
  return (
    <div className='w-screen mb-24'>
        <div className='max-w-[1350px] mx-auto'>
            <h1 className='text-gray-100 text-justify text-m m:text-xlg lg:text-2xl xl:text-4xl font-bold lg:font-custom mr-8 ml-4 md:mx-10'>
            Shawn Nathan Villanueva was born August 22, 1996 in Orlando, Florida. He showed a
love of music from an early age, playing guitar until being introduced to the trumpet at
the Osceola County School for the Arts (OCSA). Within his first few years, Shawn
began expanding his craft to include other instruments such as: bass, percussion, and
piano. Throughout high school he held lead positions in the top ensembles, as well as in
several statewide jazz bands.<br/><br/>
Outside of school, he performed with the salsa band Seguro Que Sí. In 2013,
Seguro Que Sí was hand-selected by President Barack and Michelle Obama to perform
for the President’s Inauguration, becoming the first performers of Hispanic/Latino music
to do so. In 2014, his jazz ensemble at OCSA went on to become finalists in the
prestigious Essentially Ellington Competition, performing for Wynton Marsalis at Lincoln
Center. While there, Shawn was presented an Outstanding Trumpet Soloist award by
Wynton Marsalis himself.<br/><br/>
In 2014 he graduated from OCSA and began pursuing a bachelor’s of music
degree in Jazz Performance at the Florida State University (FSU). While at FSU, he
held the lead trumpet position in their top jazz ensemble for three years. While focusing
on his school’s ensembles, Shawn was also the lead trumpet for the Tallahassee
Symphony Jazz Orchestra from 2015-2018. He also was a Florida Jazz and Blues
Festival scholarship recipient in 2017. Throughout his four years at Florida State, he
had the opportunity to learn from and perform with/for individuals such as: Jon Faddis,
Marcus Roberts, Jason Marsalis, and Wynton Marsalis.<br/><br/>
Since he graduated in May 2018, he has been freelancing and teaching
throughout the Central Florida area. He performs at all of the major theme parks
including Universal Studios, Sea World, and at Magic Kingdom in the Main Street
Philharmonic, the flagship ensemble at Walt Disney World. He plans to eventually
further his education with the hopes of being able to travel the world and spread joy,
peace, and love through his music.
            </h1>
        </div>
    </div>
  )
}

export default Bio