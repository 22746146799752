import React from 'react';
import vid2 from '../assets/bebopVid.mp4';
import vid5 from '../assets/phoneVid2.mp4';
import {DefaultPlayer as Video} from 'react-html5video'

const LandingVideo = () => {
  return (
    <div className='flex lg:show w-screen'>
        <div className='hidden xl:flex h-full w-full'>

          <div className='mx-auto'> 
          <Video 
          controls={[]}
          autoPlay muted loop id="video" className=''>
            <source src={vid2} type="video/mp4" />
          </Video>
          
       </div>
        </div>
        <div className='flex xl:hidden h-full w-full mx-auto '>
        <div className='mx-auto'> 
          <Video 
          controls={[]}
          autoPlay muted loop id="video" className='mx-auto'>
            <source src={vid5} type="video/mp4" />
          </Video>
        </div>
        </div>
    </div>
  )
}

export default LandingVideo