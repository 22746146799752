import React from 'react'

const Media = () => {
  return (
    <div className='w-screen bg-video-background bg-contain'>
        <div className='mx-auto max-w-[1240px]'>
            <div className='grid lg:grid-cols-2 gap-2 py-12 lg:py-36'>
            <div className='s:w-1/5 md:w-4/5 h-[300px] md:h-[400px] col-span-1 mx-auto'>		
                    <iframe
                        title='vid1'
       		            src="https://www.youtube.com/embed/_hN28qCiTWo"
                        className=" w-full h-full"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className='s:w-1/5 md:w-4/5 h-[300px] md:h-[400px] col-span-1 mx-auto'>		
                    <iframe
                        title='vid2'
       		            src="https://www.youtube.com/embed/kYoOMd3pdFU"
                        className=" w-full h-full"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className='s:w-1/5 md:w-4/5 h-[300px] md:h-[400px] col-span-1 mx-auto'>		
                    <iframe
                        title='vid3'
       		            src="https://www.youtube.com/embed/74HHK62kays"
                        className=" w-full h-full"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className='s:w-1/5 md:w-4/5 h-[300px] md:h-[400px] col-span-1 mx-auto'>	
                    <iframe
                        title='vid4'
       		            src="https://www.youtube.com/embed/5fI27c4OlYw"
                        className=" w-full h-full"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className='s:w-1/5 md:w-4/5 h-[300px] md:h-[400px] col-span-1 mx-auto'>	
                    <iframe
                        title='vid4'
       		            src="https://www.youtube.com/embed/Irdm73Vz9P8"
                        className=" w-full h-full"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className='s:w-1/5 md:w-4/5 h-[300px] md:h-[400px] col-span-1 mx-auto'>	
                    <iframe
                        title='vid4'
       		            src="https://www.youtube.com/embed/_QvgKNP1oZ0"
                        className=" w-full h-full"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Media