import Bio from "./components/Bio";
import LandingVideo from "./components/LandingVideo";
import Media from "./components/Media";
import Navbar from "./components/Navbar";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Form from "./components/Form";
import { Switch, Route } from "react-router-dom";

function App() {
  return (
    <div className="overflow-hidden bg-black">
    <Switch>
      <Route exact path="/">
        <Navbar/>
        <LandingVideo />
        <Media />
        <Bio />
        <Contact />
      </Route>

      <Route exact path="/contact">
        <Form />
      </Route>

    </Switch>

  
    </div>
  );
}

export default App;
