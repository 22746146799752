import React from 'react'

const Navbar = () => {
  return (
    <div className='w-screen lg:h-[300px] h-[90px] grid bg-black items-center'>  
        <div className='bg-nav-logo3 h-full bg-center bg-contain bg-no-repeat xlg:bg-cover border-none'/>
    </div>
  )
}

export default Navbar