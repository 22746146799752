import React from 'react'
import Navbar from './Navbar'
import { useHistory } from "react-router-dom";
import { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';

const Form = () => {

    const [contact, setContact] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
});

    let history = useHistory();

    const errors = useRef(false);

    const handleChange = (evt) => {
       const nextContact = {...contact};
       nextContact[evt.target.name] = evt.target.value;
       setContact(nextContact) 
    }

    const template_params = {
        firstName: contact.firstName,
        lastName: contact.lastName,
        phoneNumber: contact.phoneNumber,
        email: contact.email,
        message: contact.message
    }




    const handleSubmit = (evt) => {
        evt.preventDefault();
        totalValidation();

        if(errors.current === true){
            console.log("Errors", errors.current)
        } else {
        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_KEY, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, template_params, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
            alert("errors occured. Try again later");
            return;
        });
        alert("Your email has been received")
        history.push("/")
      }

      errors.current = false;
    }

      const totalValidation = () => {

        let errorMessages = []
        
        if(contact.firstName === "" || contact.firstName === null) {
            
            let newError = "first name is required"
            errorMessages.push(newError)
          }

          if(contact.email === "" || contact.email === null) {
            
            let newError = "email is required"
            errorMessages.push(newError)
          }
      
      
        let formattedNumber = "";
    
        for(let n of contact.phoneNumber){
          if(n !== "-"){
            formattedNumber = formattedNumber + n
          }
        } 
    
        if(formattedNumber.length !== 10 || !formattedNumber.match(/^[0-9]+$/)) {
          let newError = "Phone number is formatted incorrectly"
          errorMessages.push(newError)
        }
    
      
        if(errorMessages.length >= 1){
          console.log(errorMessages)
          errors.current = true;
          alert(errorMessages.join("\n"))
        }
    
    
      
      }


  return (
    <div className='h-screen'>

        <Link to='/'>
            <Navbar />
        </Link>

        <div className='max-w-[1240px] mx-auto mt-24'>
            <form onSubmit={handleSubmit}>
            <div className="shadow-md rounded-xl lg:px-8 px-2 pt-6 pb-8 mb-4 mx-10 bg-[#272626]">
              <p className="text-4xl pb-6 font-bold text-slate-100 pl-2">Contact Me</p>
              <div className='grid md:grid-cols-2 md:gap-12 gap-4 text-white'>
              <input
                className="bg-[#272626] border-b w-full py-2 px-3 font-bold border-slate-500/30  leading-tight focus:outline-none"
                name="firstName"
                value={contact.firstName}
                onChange={handleChange}
                type="text"
                placeholder="First Name"
              ></input>
              
              <input
                className="bg-[#272626] border-b w-full py-2 px-3 font-bold border-slate-500/30  leading-tight focus:outline-none"
                name="lastName"
                value={contact.lastName}
                onChange={handleChange}
                type="text"
                placeholder="Last Name"
              ></input>
              </div>

            
              <div className='grid md:grid-cols-2 md:gap-12 gap-4 mt-4 text-white'>
              <input
                className="bg-[#272626] border-b w-full py-2 px-3 font-bold border-slate-500/30  leading-tight focus:outline-none"
                name="phoneNumber"
                value={contact.phoneNumber}
                onChange={handleChange}
                type="text"
                placeholder="Phone Number"
                maxLength={12}
              ></input>
              <input
                className="bg-[#272626] border-b w-full py-2 px-3 font-bold border-slate-500/30  leading-tight focus:outline-none"
                name="email"
                value={contact.email}
                onChange={handleChange}
                type="text"
                placeholder="Email"
              ></input>
              </div>

            
              <textarea
                className="text-white bg-[#272626] border-b w-full px-3 font-bold border-slate-500/30  leading-tight focus:outline-none pt-6 md:pt-8"
                name="message"
                value={contact.message}
                onChange={handleChange}
                type="text"
                placeholder="Message"  
              ></textarea> 
            

            </div>
            <div className='w-full flex flex-col'>
                <div className='mx-auto'>
                <button
                className="md:w-60 w-56 h-12  mt-6 text-white rounded                      
                            bg-[#E6A500] border-slate-200 
                            hover:bg-gray-500 hover:text-[#E6A500]"
                type="submit"
                >
                Submit
                </button>
                </div>
            </div>  
            </form>
        </div>
    </div>
  )
}

export default Form